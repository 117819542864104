<template>
  <div class="page">
    <Tables
            :showSeq="false"
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
            :showRefresh="true"
            :showSearch="true"
            :handleSearch="true"
            @onSearch="getList"
            @clearSearch="clearSearch" @getList="getList"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-select v-model="searchFormData.position" placeholder="按广告位置查询" clearable size="small">
          <el-option label="支付后页面" :value="10">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
                v-model="searchFormData.group_id"
                filterable
                size="small"
                reserve-keyword
                clearable
                placeholder="请选择小区"
                v-if="$hasAccess('device.group/view')"
                :remote-method="remoteGroup"
                :loading="groupLoading">
          <el-option
                  v-for="item in groupList"
                  :key="item.group_id"
                  :label="item.group_name"
                  :value="item.group_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-date-picker class="from-item-width"
                        size="small"
                        unlink-panels
                        v-model="dates"
                        type="daterange"
                        clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">

        </el-date-picker>
      </el-form-item>
      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="position"
        title="广告位置"
        align="center"
        min-width="100px"
      >
        <template v-slot="{ row }">
          <span>{{types.position[row.position]||'未知类型'}}</span>
        </template>

      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="content"
              title="广告内容"
              align="center"
              min-width="80px"
      />
      <vxe-table-column
              slot="table-item"
              field="group_name"
              title="发放小区"
              align="center"
              min-width="80px"
      />

      <vxe-table-column
              slot="table-item"
              field="num"
              title="累计点击次数"
              align="center"
              min-width="80px"
      />
    </Tables>
  </div>
</template>

<script>
import Tables from '@/components/tables'
import upload from '@/components/upload'
export default {
  name: 'market-plan-list',
  components: {
    Tables,
    upload
  },
  data () {
    return {
      // 表格信息
      tableName: '广告信息',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      groupList: [],
      dates:[],
      status: 0,
      imageList: [],
      selecttime: {},
      selecttimeedit: {},
      groupLoading:false,
      searchFormData: {},
      types: {
        plan_type: {
          10: '进行中',
          20: '已关闭',
          30: '已完成'
        },
        position: {
          10: '支付后页面',
          20: '充电记录页面'
        },
        status: {
          10: '关闭',
          20: '开启',
          30:"关闭"
        }
      },
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      rules: {
        content: [
          { required: true, message: '请输入广告内容', trigger: 'change' }
        ],
          position: [
          { required: true, message: '请选择广告位置', trigger: 'change' }
        ],
        group_id: [
          { required: true, message: '请选择发放小区', trigger: 'change' }
        ]
      },
      // 编辑
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ['ad_id', 'content', 'position', 'group_id', 'select_time','status']
    }
  },
  computed: {
    editDisabled () {
      return false
    },   viewImgList () {
      return function (row) {
        return [row.url]
      }
    },
  },
  mounted () {
    this.init();
  },
  activated () {
    this.init();
  },
  methods: {
    // 初始化请求
    init() {
      this.remoteGroup();
    },
    // 表格列表
    async getList (currentPage, pageSize) {
      if (this.dates && this.dates.length == 2) {
        this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
        this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
      } else {
        this.searchFormData.start_time = '';
        this.searchFormData.end_time = '';
      }
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Ad.GetAdList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    async remoteGroup (query) {
      if (query !== '') {
        this.groupLoading = true
        const params = {
          token: this.$store.state.user.token
        }
        const res = await this.$api.Device.GetGroupList(params, {
          group_name: query
        })
        this.groupList = res.data
        this.groupLoading = false
      } else {
        this.groupList = []
      }
    },
    // 重置搜索
    clearSearch () {
      this.dates = [];
      this.searchFormData = {}
    },
    // 附件
    getFileInfo(fileInfo) {
      this.addDialogFormData.attach_id = fileInfo;
    },
    // 附件
    getFileInfoEdit(fileInfo) {
      this.editDialogFormData.attach_id_new = fileInfo;
    },
    // 重置form
    resetFormData () {
      this.dates = [];
      this.addDialogFormData = {
        plan_type: 10
      }
      this.imageList = [];
      this.editDialogFormData = {}
      this.editDialogFormOriData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        ad_id: row.ad_id
      }
      const res = await this.$api.Ad.GetAdDetail(params);
      if (res) {
        const { ad_id, content, position, group_id, select_time,status,attach_id } = res
        this.editDialogFormData = { ad_id, content, position, group_id, select_time ,status ,attach_id}
        this.editDialogFormOriData = {ad_id, content, position, group_id, select_time,status ,attach_id}
      }
      this.editDialogShow = true
    },
    async upStatus (row) {
      if (row.status == 10) {
        this.status = 20
      } else if (row.status == 20){
        this.status = 10
      }else {
        this.status = 20
      }
      const params = {
        token: this.$store.state.user.token,
        ad_id: row.ad_id,
        status:this.status
      }
      const res = await this.$api.Ad.upStatus(params);
      this.$notify({
        title: '成功',
        message: '更改状态成功',
        type: 'success'
      })
      this.$refs.xTable.refreshTable()
    },
    // 删除
    deleteOne (row) {
      this.$confirm('是否确定删除该条广告?', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          ad_id: row.ad_id,
          attach_id:row.attach_id
        }
        await this.$api.Ad.DeleteAd(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      if (!this.addDialogFormData.group_id) {
        this.$refs.addForm.validateField('group_id');
        return;
      }
      if (!this.addDialogFormData.content) {
        this.$refs.addForm.validateField('content');
        return;
      }
      if (!this.addDialogFormData.position) {
        this.$refs.addForm.validateField('position');
        return;
      }
      if (!this.addDialogFormData.select_time || this.addDialogFormData.select_time.length != 2) {
        this.$message.warning('请选择时间段')
        return;
      }
      if (!this.addDialogFormData.attach_id) {
        this.$message.warning('请上传图片')
        return;
      };
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.addDialogFormData.group_id == this.tableData[i].group_id&&this.addDialogFormData.position == this.tableData[i].position) {
          this.$confirm('当前小区已有广告，若再次添加会覆盖前广告。是否确定添加？', '提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.addAd();
          })
          return;
        }
      }
      this.addAd();
    },
    // 提交记录
    async addAd() {
      this.selecttime.start_time = parseInt(this.addDialogFormData.select_time[0].getTime() / 1000);
      this.selecttime.end_time = parseInt(this.addDialogFormData.select_time[1].getTime() / 1000);
      const form = {
        group_id: this.addDialogFormData.group_id,
        content: this.addDialogFormData.content,
        position: this.addDialogFormData.position,
        attach_id: this.addDialogFormData.attach_id,
        start_time:this.selecttime.start_time,
        end_time:this.selecttime.end_time
      }
      const params = {
        token: this.$store.state.user.token,
        form: JSON.stringify(form),
      }
      await this.$api.Ad.AddAd(params);
      this.$notify({
        title: "成功",
        message: "添加成功",
        type: "success",
      });
      this.closeDialog();
      this.getList();
    },
    // 编辑保存
    saveEdit () {
      if (!this.editDialogFormData.group_id) {
        this.$refs.editForm.validateField('group_id');
        return;
      }
      if (!this.editDialogFormData.content) {
        this.$refs.editForm.validateField('content');
        return;
      }
      if (!this.editDialogFormData.position) {
        this.$refs.editForm.validateField('position');
        return;
      }
      if (!this.editDialogFormData.position) {
        this.$refs.editForm.validateField('status');
        return;
      }
      if (!this.editDialogFormData.select_time || this.editDialogFormData.select_time.length != 2) {
        this.$message.warning('请选择时间段')
        return;
      }
      this.$refs.editForm.validate(async valid => {
        if (this.editDialogFormData.select_time[0].length==undefined){
          this.editDialogFormData.start_time = parseInt(this.editDialogFormData.select_time[0].getTime() / 1000);
          this.editDialogFormData.end_time = parseInt(this.editDialogFormData.select_time[1].getTime() / 1000);
        }else {
          this.editDialogFormData.start_time = this.editDialogFormData.select_time[0];
          this.editDialogFormData.end_time = this.editDialogFormData.select_time[1];
        }
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await this.$api.Ad.editGroupBill(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
